<template>
  <div class="teaching_center">
    <div class="jyzx_cnt">
      <div class="content_tit">
        <div>
          <img src="https://downloads.whzhjy.cn/desktop/image/jyzx_icon.png" alt="" />
          <div class="data_type">
            <div
              v-for="item in jyhdTypeList"
              :key="item.val"
              :class="jyzxAct === item.val ? 'typeAct' : ''"
              @click="cgJyzx(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="more" @click="open(0, '', '', 'more')">
          <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
        </div>
      </div>
      <div class="jyzx_cnt_btm" v-if="jyzx_data.length > 0">
        <div
          class="jyzx_item"
          v-for="(item, index) in jyzx_data.slice(0, 4)"
          :key="index"
          @click="open(0, item.logoutViewUrl, item)"
        >
          <img
            :src="
              item.img
                ? item.img
                : 'https://downloads.whzhjy.cn/desktop/image/jyzx_demo.png'
            "
            alt=""
          />
          <div>
            <p class="jyzx_tit overText" :title="item.title"><span v-if="item.trainingScope">【{{ rangeOpt[item.trainingScope] }}】</span>{{ item.title }}</p>
            <p class="jyzx_ctx">{{ item.context }}</p>
            <p class="jyzx_time">
              活动时间：{{ item.beginDate != " " ? item.beginDate : "-" }} ~
              {{ item.endDate != " " ? item.endDate : "-" }}
            </p>
          </div>
          <p class="status_tip" v-if="jyzxAct != 'zxzb' && jyzxAct != 'dryt'" :class="`status${ item.showStatus ? item.showStatus : item.status }`">
            <span v-if="item.showStatus">{{ item.showStatus === 1 ? "未开始" : item.showStatus === 2 ? "进行中" : "已结束" }}</span>
            <span v-else>{{ item.status === 1 ? "未开始" : item.status === 2 ? "进行中" : "已结束" }}</span>
          </p>
          <p class="status_tip" v-else :class="`status${ item.status + 1 }`">
            <span>{{ item.status === 0 ? "未开始" : item.status === 1 ? "进行中" : "已结束" }}</span>
          </p>
        </div>
      </div>
      <div class="jyzx_cnt_btm noData" v-else>
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dateFormat from "../../utils/dateFormat.js";
import { checkUserRight } from "../../utils/openPage.js";
import logMix from "../../mixin/index.js"
export default {
  name: "teaching_center",
  props: {},
  components: {},
  mixins: [logMix],
  data() {
    return {
      rangeOpt: [
        '',
        '校',
        '区',
        '市',
        '省'
      ],
      jyzxAct: "jspx",
      jyhdTypeList: [
        {
          name: "录播听评课",
          val: "zxpks",
          type: 1,
          more: "/aijy/#/activity/areaActivities/record",
          url: 'activity/areaActivities/record/liveDetails',
        },
        {
          name: "直播听评课",
          val: "zbtpk",
          type: 0,
          url: 'activity/areaActivities/live/liveDetails',
          more: "/aijy/#/activity/areaActivities/live",
        },
        {
          name: "集体备课",
          val: "jtbks",
          type: 2,
          more: "/aijy/#/activity/areaActivities/lesson",
          url: 'activity/areaActivities/lesson/lessonDetails',
        },
        {
          name: "专题研讨",
          val: "ztyts",
          type: 3,
          more: "/aijy/#/activity/areaActivities/symposium",
          url: 'activity/areaActivities/symposium/symposiumDetails',
        },
        {
          name: "教师培训",
          val: "jspx",
          type: '',
          more: "/aijy/#/activity/areaActivities/trainList",
          url: 'training/trainList/detail',
        },
        {
          name: "在线直播",
          val: "zxzb",
          type: 1,
          more: "/aijy/#/onSite/liveList",
          url: 'onSite/liveDetails',
        },
        {
          name: "多人研讨",
          val: "dryt",
          type: 0,
          more: "/aijy/#/onSite/manyPeople",
          url: 'onSite/liveDetails',
        },
        // {
        //   name: "风采活动",
        //   val: "wldss",
        //   type: 5,
        //   more: "/showme/act/actShow/index",
        //   moreLogin: true,
        // },
        // {
        //   name: "教师培训",
        //   val: "jspx",
        //   type: "",
        // },
        {
          name: "课题研究",
          val: "ktyj",
          type: '',
          more: "/subject/#/researchSubjects/notice/list?type=1",
          url: '/subject/#/researchSubjects/notice/detail',
          moreLogin: true,
        },
      ],
      jyzx_data: [],
      cacheMap: {},
    };
  },
  computed: mapState({
    homePage_data: (state) => state.user.homePage_data,
    isLogin: (state) => state.user.isLogin,
    userInfo: (state) => state.user.userInfo,
  }),
  mounted() {
    // this.getAijyData(1);
    this.getJspxData()
    SSO.getAccessTokenWithParam({appKey: '4b7eee5b4229484c88d78d15036558c9'},(res)=>{
      if(res.code != 1000){
        this.$store.commit('setAijyToken',res.data.token)
      }
    })
  },
  methods: {
    // getJyzxDataByType(type) {
    //   this.$api
    //     .getAijyData(1)
    //     .then((res) => {
    //       this.jyzx_data = res.data;
    //       this.formatNewDate();
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    open(type, url, item, more) {
      console.log(item)
      if(!this.isLogin){
        this.$message.warning('请登录后查看')
        return false
      }
      let activeItem = this.jyhdTypeList.find((item) => {
        return item.val === this.jyzxAct;
      });
      let roleList = ['teacher', 'subjectLeader', 'teamLeader', 'headmaster', 'instructor', 'edupersonnel', 'schoolAdministrator'];
      console.log(this.userInfo)
      if(Object.keys(this.userInfo).length === 0 || !this.userInfo){
        this.$store.commit('setUserInfo',JSON.parse(sessionStorage.getNavbarConfigData).user)
      }
      console.log(this.userInfo)
      let userRole = this.userInfo.usertypes
      let haveRole = ''
      haveRole = roleList.find(item=>{
        return userRole.indexOf(item) > -1
      })
      if(!haveRole){
        this.$message.warning('暂无权限！');
        return false
      }
      if(activeItem.val === 'ktyj'){
        this.appLog('教研课题')
      } else {
        this.appLog('智慧教研')
      }
      if (more) {
        window.open(`https://${window.location.host}${activeItem.more}`);
        return false;
      }
      if(item.oldTeachResearchId) {
        let url = ''
        if(item.fromSource === 'oldTkpk'){
          url = `/area/onlineLesson_onlineLessonView.action?manage=1&id=${item.oldTeachResearchId}`
        } else if(item.fromSource === 'oldGkjx'){
          url = `/area/syncLesson_syncLessonView.action?manage=1&id=${item.oldTeachResearchId}`
        } else if(item.fromSource === 'oldJtbk'){
          url = `/school/collectiveLesson_schColLessonRead.action?&id=${item.oldTeachResearchId}`
        } else if(item.fromSource === 'oldZtyt'){
          url = `/school/collectiveTheme_schColThemeRead.action?&id=${item.oldTeachResearchId}`
        } else if(item.fromSource === 'oldZxpx'){
          url = `/area/onlineTrain_joinTrain.action?trainId=${item.oldTeachResearchId}`
        }
        let baseUrl =  `${window.location.host === 'whzhjy.cn' ? 'https://whzhjy.cn' : 'https://wuhutestbasic.changyan.cn'}`
        url = `${baseUrl}${window.location.host === 'whzhjy.cn' ? '' : '/qxpt_third'}${url + '&noheader=1'}`
        let routeUrl = this.$router.resolve({
          path: '/oldActivityDetail',
          query: {
            url: url
          }
        });
        window.open(baseUrl +'/aijy/'+ routeUrl.href, "_blank");
        // window.open(`${window.location.host === 'whzhjy.cn' ? 'https://whzhjy.cn' : 'https://wuhutestbasic.changyan.cn/qxpt_third'}${url}`)
        return false
      }
      if(activeItem.val === 'jspx'){
        this.goTrainListDetail(item);
      } else if(activeItem.val === 'zxzb' || activeItem.val === 'dryt'){
        this.handleLiveAndSeminar(item, activeItem.val);
      } else if(activeItem.val === 'ktyj'){
        window.open(`https://${window.location.host}${activeItem.url}?noticeId=${this.Base64.encode(String(item.id))}`);
      } else {
        const parmas = {
          page: 1,
          activeStatus: item.status,
        };
        const noteParams = {
          from: "homePage",
        };
        window.open(
          `${
            window.location.origin
          }/aijy/#/${activeItem.url}?id=${this.Base64.encode(
            String(item.id)
          )}&fromIndex=${this.Base64.encode("0")}&listParams=${this.Base64.encode(
            JSON.stringify(parmas)
          )}&noteInfo=${this.Base64.encode(JSON.stringify(noteParams))}`
        );
      }

      //https://wuhutestbasic.changyan.cn/aijy/#/activity/areaActivities/live/liveDetails?id=NTk2&fromIndex=MA%3D%3D&listParams=e30%3D&noteInfo=eyJmcm9tIjoiaG9tZVBhZ2UifQ%3D%3D
    },
    handleLiveAndSeminar(item, type) {
        //   是否是主讲人
        const isSpeaker = item.liveRoom.userList && item.liveRoom.userList.find(
          (elem) => elem.userId === this.userInfo.id && elem.userType === 0
        );
        //   是否是20分钟内
        const isTime = new Date(item.liveRoom.startTime.replace(/-/g, '/')) - new Date() - 1200000 > 0;
      if (type === 'zxzb' && item.joinMethod == 3) {
        // 未开始主讲人 或者 离直播开始前20分钟不给进去
        if ((isSpeaker && isTime) || (item.liveRoom.status === 0 && !isSpeaker)) {
          this.$message.warning('尚未开始，请稍后...');
          return;
        }
        // 在线直播且活动完全公开 joinMethod 是参与方式：1：个体参与,2群体参与,3完全公开
        // 直播已结束时验证回看范围
        if (item.liveRoom && item.liveRoom.status === 2 && item.replayPermission === false) {
          this.$message.warning(item.replayRange === '1'? '您不是活动参与人，目前无法查看详情': '该直播回看视频未公开，无法查看');
          return;
        }
        //进入直播
        window.open(
          `${window.location.origin}/aijy/#/enterPage?id=${btoa(item.id)}`
        );
      } else { 
        // 在线直播(非完全公开) & 多人研讨
        this.goLiveDetail(item, isSpeaker, isTime); 
      }
    },
    //    进入直播和多人研讨详情
    goLiveDetail(item, isSpeaker, isTime) {
      // 已结束所有人可以进
      // 未开始主讲人可以进 其他人前20分钟才能进
      // 正在进行中 主讲人 参与人可进
      // 创建人3 主讲人 0  参与人 1
      //   未开始、进行中
      if (item.liveRoom && item.liveRoom.status !== 2) {
        // 是否是参与人
        const isParticipants = item.liveRoom.userList && item.liveRoom.userList.find(
          (elem) => elem.userId === this.userInfo.id
        );
        // 未开始主讲人 或者 离直播开始前20分钟不给进去
        if ((isSpeaker && isTime) || (item.liveRoom.status === 0 && !isSpeaker)) {
          this.$message.warning('尚未开始，请稍后...');
          return;
        }
        //正在直播不是不是参与人不给进
        if (item.liveRoom.status === 1 && !isParticipants) {
          this.$message.warning(' 您不是活动参与人，目前无法查看详情...');
          return;
        }

      }
    //   已结束 所有人可以进
      if (item.liveRoom && item.liveRoom.status === 2) {
        this.backSeeLive(item);
      } else {
        this.intoLiveRoom(item);
      }
    },
    // 回看直播
    backSeeLive(val) {
      if (val.replayPermission === false) {
        this.$message.warning(val.replayRange === '1'? '您不是活动参与人，目前无法查看详情': '该直播回看视频未公开，无法查看');
        return;
      }

      let id = this.Base64.encode(val.id);
      let roomId = this.Base64.encode(val.liveRoom.roomId);
      let types = null;
      if (this.jyzxAct === 'zxzb') {
        types = 'liveList';
      } else {
        types = 'manyPeople';
      }
      window.open(
        `${window.location.origin}/aijy/#/onSite/liveDetails?id=${id}&roomId=${roomId}&type=${types}`
      );
    },
    //进入直播间
    async intoLiveRoom(info) {
      let newWin = window.open();
      const { userInfo } = this;
      // 获取直播appId 跳转直播加参数appSecret=${res}
      this.getLiveRoomStatus(info);
      this.$api.getLiveAppSecret({
        liveAppId: info.liveRoom.liveAppId
      })
        .then((res) => {
          if (res && !res.code) {
            const livePramas = `userId=${userInfo.id}&userName=${userInfo.name}&appId=${info.liveRoom.liveAppId}&appSecret=${res.data}&roomId=${info.liveRoom.roomId}&ssoKey=4b7eee5b4229484c88d78d15036558c9&appKey=4b7eee5b4229484c88d78d15036558c9&appSecret=52B9EB0F9DE3633D7FDAC30E63E9D8E7`;
            const href = `${window.location.origin}/localLive/?AITar=${this.Base64.encode(encodeURIComponent(livePramas))}`;
            newWin.location = href;
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    // 获取活动状态
    async getLiveRoomStatus(info) {
      let pams = {
        roomId: info.liveRoom.roomId
      };
      this.$api.getLiveRoomStatus(pams).then(res=>{
        console.log(res)
        if (res.data.data === 0) {
          //res表示当前直播状态，0未开始，1已开始，2结束
          this.updateStatus(info);
        }
      });
    },
    // 更新活动状态
    updateStatus(info) {
      let pams = {
        lecturerId: this.userInfo.id,
        roomId: info.liveRoom.roomId,
        status: 1
      };
      this.$api.callBackLiveStatus(pams)
        .then((res) => {
          this.getZxzbData(1);
        })
        .catch((err) => { });
    },
    // 进入师训详情
    goTrainListDetail(item) {
      if (item.participateWay === 2 && !item.signUp) {
        if (item.showStatus == 3) {
          //   return this.$message.warning('该培训已结束，无法进行报名');
          this.handleIntoDetail(item);
          return;
        }
        this.$confirm(`确定参加${item.trainingName}培训？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: ''
        })
          .then(() => {
            this.handeleEnroll(item);
          })
          .catch((err) => {
            this.$message({
              type: 'info',
              message: '已取消报名'
            });
          });
      } else {
        this.handleIntoDetail(item);
      }
    },
    async handleIntoDetail(item) {
      // const {
      //   pageNumber,
      //   avtiveIdData
      // } = this;
      // const parmas = {
      //   pageNumber,
      //   avtiveIdData
      // };
      // const { href } = this.$router.resolve({
      //   path: '/training/trainList/detail',
      //   query: {
      //     id: this.Base64.encode(item.id),
      //     fromIndex: this.Base64.encode(0),
      //     listParams: this.Base64.encode(JSON.stringify(parmas))
      //   }
      // });
      window.open( `/aijy/#/training/trainList/detail?id=${this.Base64.encode(''+item.id)}&fromIndex=${this.Base64.encode('0')}&listParams=e30%3D`);
    },
    // 自主报名
    handeleEnroll(item) {
      this.$api.signUpTrainingPlan({
        trainingPlanId: item.id
      }).then(res=>{
        if (res && !res.code) {
          this.$message.success('报名成功');
          this.handleProjectUserSign(item);
          this.handleIntoDetail(item);
        }
      })
    },
    // 报名成功后添加用户参与小工具
    handleProjectUserSign(data) {
      this.$api.listTrainingPlanOfflineToos({
        trainingPlanId: data.id
      }).then(res=>{
        if (res && !res.code) {
          const params = res.data.data.map((item) => {
            return {
              projectBid: item.toolId
            };
          });
          this.$api.userSign(params);
        }
      })
    },
    async cgJyzx(item) {
      this.jyzxAct = item.val;
      const cacheData = this.cacheMap[item.val]
      if (cacheData) {
        this.jyzx_data = cacheData
        return
      }
      if(item.val === 'jspx'){
        await this.getJspxData()
      } else if(item.val === 'zxzb' || item.val === 'dryt'){
        await this.getZxzbData(item.type)
      } else if(item.val === 'ktyj'){
        await this.getKtyjData()
      } else{
        await this.getAijyData(item.type);
      }
      this.cacheMap[item.val] = this.jyzx_data
    },
    formatNewDate() {
      let newTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
      this.jyzx_data.forEach((item) => {
        // item.startTimes = item.beginDate +' '+ item.beginTime;
        // item.endTimes = item.endDate +' '+ item.endTime;
        item.context ? item.context = item.context
          .replace(/<\/?.+?>/g, "")
          .replace(/ /g, "")
          .replace(/&nbsp;/gi, "") : '';
        if(item.status || item.showStatus) {
          return false
        }
        if (item.beginDate > newTime) {
          item.status = 1;
        } else if (item.endDate < newTime) {
          item.status = 3;
        } else {
          item.status = 2;
        }
      });
    },
    async getAijyData(type) {
      try {
        const res = await this.$api.getAijyData(type)
        res.data.data.forEach((item) => {
          item.title = item.name;
          item.context = item.introduce;
          item.img = item.coverPreviewUrl
        });
        this.jyzx_data = res.data.data;
        this.formatNewDate();
      } catch (err) {
        console.log(err);
      }
    },
    async getJspxData() {
      try {
        const res = await this.$api.getJspxData()
        res.data.data.forEach((item) => {
          item.title = item.trainingName;
          item.context = item.introduce;
          item.beginDate = item.startTime
          item.endDate = item.endTime
          item.img = item.thumbPreviewUrl
        });
        this.jyzx_data = res.data.data;
        this.formatNewDate();
      } catch (err) {
        console.log(err);
      }
    },
    async getZxzbData(type) {
      try {
        const res = await this.$api.getZxzbData(type)
        res.data.data.forEach((item) => {
          // item.title = item.title;
          // item.context = item.introduce;
          item.beginDate = item.liveRoom.startTime;
          item.endDate = item.liveRoom.endTime
          item.img = item.thumbCoverUrl
        });
        this.jyzx_data = res.data.data;
        // this.formatNewDate();
      } catch (err) {
        console.log(err);
      }
    },
    async getKtyjData() {
      try {
        const res = await this.$api.homePageNoticeList()
        res.data.data.forEach((item) => {
          item.context = item.content;
          item.beginDate = item.startTime
          item.endDate = item.endTime
          item.img = item.previewUrl
        });
        this.jyzx_data = res.data.data;
        this.formatNewDate();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.teaching_center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 40px;
}
.jyzx_cnt {
  width: 1200px;
}
.jyzx_cnt_btm {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .jyzx_item {
    width: 588px;
    padding: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
    box-shadow: 0px 0px 18px 0px rgba(77, 100, 128, 0.12);
    border-radius: 4px;
    border: 2px solid #ffffff;
    cursor: pointer;
    position: relative;
    display: flex;
    margin: 0 24px 24px 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
    img {
      display: block;
      width: 200px;
      height: 112px;
      border-radius: 4px;
    }
    div {
      padding-left: 20px;
    }
    .jyzx_tit {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 20px;
      padding: 4px 0 8px;
      width: 308px;
    }
    .jyzx_ctx {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 22px;
      height: 44px;
      width: 308px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .jyzx_time {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 18px;
      padding-top: 11px;
    }
    &:hover {
      background: linear-gradient(180deg, #ebeff8 0%, #ffffff 100%);
      box-shadow: 0px 0px 16px 0px rgba(187, 202, 216, 0.49);
      border: 2px solid #368fff;
      .jyzx_cnt {
        background: transparent;
      }
    }
    .status_tip {
      position: absolute;
      right: 0px;
      top: 8px;
      width: 56px;
      height: 23px;
      background: linear-gradient(270deg, #00baff 0%, #00deff 100%);
      border-radius: 13px 0px 0px 13px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      color: #fff;
      &.status1 {
        background: linear-gradient(270deg, #ffb820 0%, #ffda32 100%);
      }
      &.status3 {
        background: linear-gradient(270deg, #bbc8e2 0%, #ceddfb 100%);
      }
    }
  }
  &.noData {
    height: 360px;
  }
}
.noData {
  width: 100%;
  background: url("https://downloads.whzhjy.cn/desktop/image/nodata.png") no-repeat center
      center,
    #fff;
  p{
    padding-top: 300px;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
}
</style>
