<!--
 * @Author: qinzhang14
 * @Date: 2022-05-17 21:14:49
 * @LastEditTime: 2025-03-10 11:48:01
 * @LastEditors: qinzhang14
 * @Description: 特色专栏
 * @FilePath: \desktop-static\app-home\src\views\home_com\column.vue
-->
<template>
  <div class="g-wrapper z-column">
    <div id="driver-column" class="g-contain">
      <div class="swiper-container carousel-img" id="swiper_container_column">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="el in columns" :key="el.code" :data-code="el.code">
            <div
              class="column-list-item"
              >
              <div class="img-con">
                <img class="custom-image" :src="el.cover" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="columns.length > 3"
          class="swiper-button-prev swiper-button-prev-one swiper-button-prev-b"
          slot="button-prev"
        ><i class="el-icon-arrow-left"></i></div>
        <div
          v-show="columns.length > 3"
          class="swiper-button-next swiper-button-next-one swiper-button-next-b"
          slot="button-next"
        ><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import API from "@/api/api.js";
import 'swiper/dist/css/swiper.css';
import { mapState } from "vuex";
export default {
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      swiper_one: null,
      showColumns: false,
      columns: [
      ]
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.login.token,
    isLogin: (state) => state.user.isLogin,
  }),
  },
  mounted() {
    this.getSpecialList()
  },
  methods: {
    initSwiper() {
      this.swiper_one = new Swiper("#swiper_container_column", {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: this.columns.length > 3,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        // 如果需要分页器
        prevButton: ".swiper-button-prev-one",
        nextButton: ".swiper-button-next-one",
        onClick: (swiper) => {
          console.log(swiper.clickedSlide);
          const e = swiper.clickedSlide
          
          const code = e.getAttribute('data-code')
          console.log(e,code);
          if(code) {
            this.goTo(code)
          }
        }
      });
    },
    /* 获取专题列表 */
    getSpecialList() {
      let that = this;
      let params = {
        pageNo: 1,
        pageSize: 1000,
        module: 'clb'
        // showSubjects: true,
      };
      API.getSpecialList(params)
        .then((result) => {
          const res = result.data
          console.log(res);
          if (res.code == '1') {
            const tempList =
            res.data && res.data.list && res.data.list.length > 0
            ? res.data.list
            : [];
            // this.swiperOptions.loop = tempList.length > 3
            that.columns = [...tempList];
            this.$nextTick(() => {
              this.initSwiper()
            })
          }
        })
        .catch((err) => {
        });
    },
    goTo(code) {
      if (!this.isLogin) {
        this.$message.warning("请登录后查看");
        return false;
      }
      window.open(`/resource-center/front/#/layout/front/regional-resource/special-resource/${code}?customTagCode=04&type=tszy`, '_blank') 
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper_container_column{
  width: 100%;
}
.z-column {
  // padding: 30px 0 40px;
  width: 100%;
  min-height: 117px;
  background-size: 100%;
}
.swiper-slide{
  width: 350px;
  height: 117px;
}
.column-list-item {
  display: block;
  width: 350px;
  height: 117px;
  background-color: #fff;
  box-shadow: 0px 5px 8px 0px rgba(112, 161, 221, 0.12);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    > .img-con .custom-image {
      transform: scale(1.2);
    }
  }
  > .img-con {
    width: 350px;
    height: 117px;
    overflow: hidden;
    border-radius: 8px;
    .custom-image {
      width: 350px;
      height: 117px;
      transition: all 0.5s;
    }
  }
}
.carousel-img {
  height: 117px;
  // padding-bottom: 30px;
}
.swiper-button-custom {
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  pointer-events: auto;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::after {
    font-size: 14px;
    color: #fff;
  }
}
</style>
